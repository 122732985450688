<template>
    <div style="background-color: #000000;">
        <v-container class="hidden-sm-and-down">
            <v-container style="padding: 115px 0px 0px;">
                <v-row>
                    <v-col lg="6">
                        <div class="pt-16">
                            <h1 class="intro-text-head">¡Encuentra la </h1>
                            <h1 class="intro-text-head"> vida que quieres!</h1>
                            <br>
                            <p class="intro-text-head-desc">
                                Descubre <span style="background-color: #7579E7; color: white; font-weight: 600; border-radius: 10px;"> oportunidades</span>, personalizadas a tus objetivos de vida.
                            </p>
                        </div>

                        <br>
                        <a href="https://tally.so/r/wzEVv8" style="text-decoration: none;">
                            <v-btn rounded color="#7579e7" class="white--text">
                                Registrarme
                                <v-icon small right>
                                    mdi-arrow-right
                                </v-icon>

                            </v-btn>
                        </a>
                    </v-col>

                    <v-col lg="6" flex>
                        <img src="https://d1kz8c63g8kbpa.cloudfront.net/web/intro-personas-image.png" alt="" width="95%"
                            mx-auto />
                    </v-col>
                </v-row>
                <br>
            </v-container>
        </v-container>

        <v-container class="hidden-md-and-up">
            <v-container class="mx-1" style="padding: 115px 0px 0px;">
                <v-row>
                    <v-col lg="6">
                        <h1 class="intro-text-head">¡Encuentra la vida que quieres!</h1>
                        <br>

                        <div>
                            <p class="intro-text-head-desc">
                                Descubre <span style="background-color: #7579E7; color: white;"><b>
                                        oportunidades</b></span><b> gratis</b>, personalizadas a tus objetivos de vida.
                            </p>
                        </div>

                        <br>
                        <a  href="https://tally.so/r/wzEVv8"  style="text-decoration: none;">
                            <v-btn rounded color="#7579e7" class="white--text">
                                Registrarme
                                <v-icon small right>
                                    mdi-arrow-right
                                </v-icon>

                            </v-btn>
                        </a>

                        <img class="pt-16"
                            src="https://d1kz8c63g8kbpa.cloudfront.net/web/intro-personas-image-final.png" alt=""
                            width="100%" mx-auto />
                    </v-col>
                </v-row>
                <br>
            </v-container>
        </v-container>

    </div>
</template>

<script>

// @ is an alias to /src  

export default {
    name: 'HomeView',
    components: {
    },
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: #ffffff;
    font-size: 55px;
    line-height: 65px;
}

.intro-text-head-desc {
    color: white;
    font-size: 25px;
    font-weight: 350;
}
</style>

