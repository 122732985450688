<template>
  <div>
    <v-container class="hidden-sm-and-down">
      <v-container style="padding: 50px 0px 0px;">
        <center>
          <div style="line-height: 1;" class="pb-16">
            <p class="form-text-head">Pre - registrate. 👇 </p>
            <p class="form-text-desc">Te avisaremos para que seas de los primeros en acceder.</p>
          </div>
        </center>
        <v-row>
          <v-col lg="6" flex>
            <img src="https://d1kz8c63g8kbpa.cloudfront.net/web/mockup-personas-pc.png" alt="" width="85%" mx-auto />
          </v-col>

          <v-col lg="6">
            <div id="app-pc" style="background-color: white; border-radius: 20px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);">
              <div ref="tallyContainer" class="ma-5"></div>
            </div>
          </v-col>
        </v-row>


      </v-container>
    </v-container>

<!--CELULAR-->
    <v-container class="hidden-md-and-up">
      <v-container style="padding: 0px 0px 0px;">

        <center>

          <div style="line-height: 1;" class="pb-5" id="section-mobile">
            <p class="form-text-head">Pre - registrate. 👇 </p>
            <p class="form-text-desc">Te avisaremos para que seas de los primeros en acceder.</p>
          </div>
        </center>
        <v-row>
          <v-col lg="6">
            <div id="app-mb" style="background-color: white; border-radius: 20px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);">
              <div ref="tallyContainerMobile" class="ma-5"></div>
            </div>
          </v-col>
        </v-row>


      </v-container>
    </v-container>


    <br>
    <br>
  </div>
</template>

<script>

// @ is an alias to /src  

export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
    }
  },

  mounted() {

    //Pc
    const tallyContainer = this.$refs.tallyContainer;
    const tallyIframe = document.createElement("iframe");

    tallyIframe.setAttribute(
      "src",
      "https://tally.so/embed/wzEVv8?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
    );
    tallyIframe.setAttribute("loading", "lazy");
    tallyIframe.setAttribute("width", "100%");
    tallyIframe.setAttribute("height", "530");
    tallyIframe.setAttribute("frameborder", "0");
    tallyIframe.setAttribute("marginheight", "0");
    tallyIframe.setAttribute("marginwidth", "0");
    tallyIframe.setAttribute("title", "Pill B2C MVP");

    tallyContainer.appendChild(tallyIframe);

    //Mobile

    const tallyContainerMobile = this.$refs.tallyContainerMobile;
    const tallyIframeMobile = document.createElement("iframe");

    tallyIframeMobile.setAttribute(
      "src",
      "https://tally.so/embed/wzEVv8?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
    );
    tallyIframeMobile.setAttribute("loading", "lazy");
    tallyIframeMobile.setAttribute("width", "100%");
    tallyIframeMobile.setAttribute("height", "530");
    tallyIframeMobile.setAttribute("frameborder", "0");
    tallyIframeMobile.setAttribute("marginheight", "0");
    tallyIframeMobile.setAttribute("marginwidth", "0");
    tallyIframeMobile.setAttribute("title", "Pill B2C MVP");

    tallyContainerMobile.appendChild(tallyIframeMobile);
  },

}


</script>

<style scoped>
.v-btn {
  text-transform: none;
}

.form-text-head {
  color: rgb(255, 255, 255);
  font-size: 28px;
  line-height: 65px;
}

.form-text-desc {
  color: rgb(255, 255, 255);
  font-size: 25px;
  font-weight: 350;
}
</style>

