<template>
    <div>
        <v-container class="hidden-sm-and-down">
        <v-container style="padding: 50px 0px 0px;">
          <center>
          <div style="width: 50%;" class="pb-16" id="section-mobile">
            <p class="intro-text-head-desc">Pill es una <b>herramienta de búsqueda avanzada</b> que te ayuda a descubrir oportunidades. 🔎</p>
            <br>
          </div>
        </center>
            <v-row>
            <v-col lg="6">
                <div style="width:400px">
                    <p class="intro-text-head-desc">Créa tu <u>camino de vida</u> y recibe solamente lo que estas buscando <b>(Empleos, Becas y más)</b></p>
                    <p class="intro-text-head-desc"><b><span style="background-color: #7579E7">Con Inteligencia Artificial.</span></b></p>
                </div>
                <br>
                <br>
                <div style="line-height: 1;">
                    <p class="intro-text-head-desc">Cada camino es diferente. </p>
                    <!-- <p class="intro-text-head-desc"><b>!La vida es muy corta como para no hacer lo que amas! ❤️‍🔥</b></p> -->
                </div>
                

            </v-col>

            <v-col lg="6" flex>

                <div class="carousel-container mx-auto">
                    <img class="carousel-image " :src="images[currentImage]" alt="Image" width="90%" >
                </div>
            </v-col>
            </v-row>
            <br>
        </v-container>
        </v-container>


        <v-container class="hidden-md-and-up">
        <v-container class="mx-1" style="padding: 20px 0px 0px;">

            <div id="section-mobile">
              <p class="intro-text-head-desc">Pill es una <b>herramienta de búsqueda avanzada</b> que te ayuda a descubrir oportunidades. 🔎</p>
            </div>
            <v-row>
            <v-col lg="6">
              <div class="carousel-container-mobile ">
                  <center>
                    <img class="carousel-image-mobile  pt-8 pb-10"  :src="images[currentImage]" alt="Image" width="90%" >
                  </center>
                </div>
                    <p class="intro-text-head-desc">Créa tu <u>camino de vida</u> y recibe solamente lo que estas buscando (Empleos, Becas y más)</p>
                    <p class="intro-text-head-desc"><b><span style="background-color: #7579E7">Con Inteligencia Artificial.</span></b></p>
                <br>
                <br>
                <div style="line-height: 1;">
                    <p class="intro-text-head-desc">Cada camino es diferente. </p>
                    <!-- <p class="intro-text-head-desc"><b>!La vida es muy corta como para no hacer lo que amas! ❤️‍🔥</b></p> -->
                </div>
                <center>
                  <img src="https://d1kz8c63g8kbpa.cloudfront.net/web/mockup-personas-mobile.png" alt="" width="90%" class="pb-1 pt-10"/>
                </center>
                

            </v-col>
            </v-row>
            <br>
        </v-container>
        </v-container>



    </div>     
</template>

<script>

// @ is an alias to /src  

export default {
name: 'HomeView',
components: {
},
data() {
    return {
      currentImage: 0,
      images: [
        'https://d1kz8c63g8kbpa.cloudfront.net/web/road-1.png',
        'https://d1kz8c63g8kbpa.cloudfront.net/web/road-2.png',
        'https://d1kz8c63g8kbpa.cloudfront.net/web/road-3.png'
      ]
    }
  },
  mounted() {
    setInterval(() => {
      this.currentImage = (this.currentImage + 1) % this.images.length;
    }, 1500);
  }
}
</script>

<style scoped>

body{
    overflow-x: hidden;
}

.v-btn {
text-transform: none;
}


.intro-text-head{
color: #ffffff;
font-size: 55px;
line-height: 65px;
}

.intro-text-head-desc{
color: white;
font-size: 25px;
font-weight: 350;
}


/* CARRUSEL DE IMAGENES*/

.carousel-container {
  height: 450px;
  width: 450px;
  position: relative;
  overflow: hidden;
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel-container-mobile {
  width: fit-content;
  position: relative;
  overflow: hidden;
}

.carousel-image-mobile {
  top: 0;
  left: 0;
}





</style>

