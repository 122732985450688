<template>
    <div>
    <v-app>
      <Header />
      <Intro id="section-one" />
      <v-main style="padding: 0px 0px 0px; background-image: linear-gradient(to bottom, #000000, #000000, #3b3b3b, #777777, #b9b9b9, #ffffff);;">
        <Info id="section-two" />
        <Form id="section-three"/>
      </v-main>
      <Footer />

    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/landing_personas/headerPersonas.vue'
import Intro from '@/components/landing_personas/introPersonas.vue'
import Info from '@/components/landing_personas/infoPersonas.vue'
import Form from '@/components/landing_personas/suscriptionForm.vue'
import Footer from '@/components/landing_personas/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    Intro,
    Info,
    Form,
    Footer
  },
}
</script>



