<template>
    <div class="video-wrapper">
        <video playsinline autoplay muted loop>
            <source src="https://d153chakadu90d.cloudfront.net/web/wall_video.webm" type="video/webm">
            <source src="https://d153chakadu90d.cloudfront.net/web/wall_video.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <!-- This will be positioned on top of our video background -->
        <div class="header hidden-sm-and-down">
            <v-row>
                <v-col style="text-align: left; margin-left: 100px; padding-top: 10rem;">
                    <v-img src="https://d153chakadu90d.cloudfront.net/web/1_Free_ATS.webp" contain alt="Pill is the #1 Free ATS" class="mb-10" max-width="135"></v-img>
                    <h2 class="intro-text-head" style="font-weight: 550;">¡ <span
                            style="background-color: black; border-radius: 10px; color: white"><span
                                class="mx-2">Adiós</span></span> a las hojas<br> de vida por <span
                            style="background-color: black; border-radius: 10px; color: white"><span
                                class="mx-2">correo</span></span> !</h2>
                    <p class="intro-text-head-desc mt-7">Optimiza tus procesos de <br>selección. <span
                            style="background-color: black; border-radius: 10px; color: white"><span class="mx-2"
                                style="font-weight: 500;">Gratis</span></span></p>
                    <v-btn @click="goToRegister()" rounded color="#7579E7" class="mt-8 white--text">
                        <span style="font-weight: 500;">Registrarme</span> <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="6" style="text-align: left;  padding-top: 7rem;">
                    <v-img src="https://d153chakadu90d.cloudfront.net/web/pill_platform_preview_2.webp" max-width="1200"
                        max-height="1200" contain alt="Logo AI" style="position: absolute;"></v-img>
                </v-col>
            </v-row>
        </div>
        <div class="header hidden-md-and-up">
            <center>
                <h2 class="intro-text-head-mb" style="padding-top: 14rem; font-weight: 550;">¡ <span
                        style="background-color: black; border-radius: 10px; color: white"><span
                            class="mx-2">Adiós</span></span> a las hojas<br> de vida por <span
                        style="background-color: black; border-radius: 10px; color: white"><span
                            class="mx-2">correo</span></span> !</h2>
                <p class="intro-text-head-desc-mb mt-7">Optimiza tus procesos de selección. <span
                        style="background-color: black; border-radius: 10px; color: white"><span class="mx-2"
                            style="font-weight: 500;">Gratis</span></span></p>
                <v-btn @click="goToRegister()" rounded color="#7579E7" class="mt-3 white--text">
                    <span style="font-weight: 500;">Registrarme</span> <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <v-img src="https://d153chakadu90d.cloudfront.net/web/platform_preview_mb.webp" max-width="1200"
                        max-height="1200" contain alt="Pill ATS Gratuito plataforma" class="mt-16"></v-img>
            </center>
        </div>
    </div>
</template>

<script>

// @ is an alias to /src  

export default {
    name: 'HomeView',
    components: {
    },

    methods: {
        goToRegister() {
            window.open('https://app.pill.com.co/register', "_self");
        },
    }
}
</script>

<style scoped>
/* Style to make the video cover the entire component */
.header {
    position: relative;
    color: white;
    /* text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.6); */
}

/* Style for the content overlaying the video */
video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.video-wrapper {
    width: 100%;
    height: 850px;
    position: relative;
    overflow: hidden;
    text-align: center;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
}

.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: black;
    font-size: 45px;
    line-height: 65px;
    text-align: left;
    font-weight: 500;
    text-shadow: none !important;

}

.intro-text-head-mb {
    color: #000000;
    font-size: 35px;
    line-height: 55px;
    font-weight: 600;

}

.intro-text-head-desc {
    color: black;
    font-size: 25px;
    font-weight: 400;
    text-align: left;
}


.intro-text-head-desc-mb {
    color: rgb(0, 0, 0);
    font-size: 25px;
    font-weight: 400;
    text-align: center;
}
</style>
